import { Component } from 'preact';
import { route } from 'preact-router';
import style from './style';
import items from '../items/items.json';

export default class Item extends Component {
  state = {item: {}, text: ''};

  queryItem(query) {
    let { item } = this.state;
    let possItem = items.items.find(i => i.id === query);
    const invalidText = 'Virheellinen haku';
    item = possItem ? possItem : {name: '', id: '', desc: invalidText, imgUrl: ''}
    if (query === '') item.desc = '';
    this.setState({ item, text: '' });
  }

  redirectToItem(query) {
    route(`/${query}`, true);
    this.queryItem(query);
  }

  handleInputChange = e => {
      this.setState({ text: e.target.value });
      if (e.target.value.length === 3) {
        this.redirectToItem(e.target.value);
        e.target.blur();
      }
  };

	componentDidMount() {
    const searchId = this.props.id || '';
    this.queryItem(searchId);
  }

	render({ }, { item, text }) {
		return (
      <div>
      <header class={style.header}>
      <div class={style.header__flex1} />
      <div class={style.header__flex2}><a href="https://hankkijat.com/"><img src="https://hankkijat.com/images/Hankkijalogovektoreina.svg" /></a></div>
      <div class={style.header__flex3} />
      </header>
      <div class={style.itemPage}>
        <div class={style.itemSearch}>
          <input class={style.newinput} maxlength='3' placeholder={item.id || this.props.id ? `${item.id || this.props.id}` : '123'} type="number" value={text} onInput={this.handleInputChange} pattern="[0-9]*" />
        </div>
        <div class={style.itemResult}>
          <h1 class={style.itemResult__name}>{item.name}</h1>
          {item.meta ? <h3 class={style.itemResult__meta}>{item.meta}</h3> : ''}
          <p class={style.itemResult__desc}>{item.desc}</p>
          <p class={style.itemResult__image}><img src={item.imgUrl} /></p>
        </div>
			</div>
      </div>
		);
	}
}
